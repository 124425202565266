var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('head-tab', {
    attrs: {
      "tit": "시사토픽"
    }
  }, [_c('v-tabs', {
    staticClass: "v-tabs--punch",
    attrs: {
      "color": "grey-9",
      "hide-slider": ""
    },
    model: {
      value: _vm.filter.tab,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "tab", $$v);
      },
      expression: "filter.tab"
    }
  }, [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.input(null, 0);
      }
    }
  }, [_vm._v("All")]), _vm._l(_vm.category.children, function (children, index) {
    return _c('v-tab', {
      key: children._id,
      on: {
        "click": function ($event) {
          return _vm.input(children._id, index + 1);
        }
      }
    }, [_vm._v(_vm._s(children.name))]);
  })], 2)], 1), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "disabled": !_vm.filter.searchKey
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14",
    on: {
      "click": function ($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("검색")])])], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-n10 my-lg-n20"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-10 py-lg-20",
      attrs: {
        "cols": "6",
        "md": "4"
      },
      on: {
        "click": function ($event) {
          return _vm.move(board);
        }
      }
    }, [_c('board-item-primary', {
      attrs: {
        "board": board
      }
    })], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }