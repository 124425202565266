<template>
    <div class="board__list">
        <head-tab tit="시사토픽">
            <v-tabs v-model="filter.tab" color="grey-9" hide-slider class="v-tabs--punch">
                <v-tab @click="input(null, 0)">All</v-tab>
                <v-tab v-for="children, index in category.children" :key="children._id" @click="input(children._id, index + 1)">{{ children.name }}</v-tab>
            </v-tabs>
        </head-tab>

        <div class="mb-20 mb-lg-40">
            <v-row class="row--x-small search--primary">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details ></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey" />
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                        <span class="white--text font-size-14" @click="search()">검색</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <v-row class="my-n10 my-lg-n20">
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4" class="py-10 py-lg-20" @click="move(board)">
                <board-item-primary :board="board" />
            </v-col>
        </v-row>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import HeadTab from "@/components/client/sub/head-tab.vue";
import SearchPrimary from "@/components/client/board/search--primary.vue";
import BoardItemPrimary from "@/components/client/board/board-item--primary.vue";

export default {
    props: {
        value: {
            type: Object,
            default: null,
        },
        code: {
            type: String,
            default: null,
        },
        justifyEnd: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
    },
    components: {
        HeadTab,
        SearchPrimary,
        BoardItemPrimary,
    },
    data() {
        return {
            boards: [],

            page: 1,
            limit: 6,
            pageCount: 0,
            category: {},

            filter: {
                code: this.code,
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
                sortKey: this.$route.query.sortKey || null,
                _category: this.$route.query._category || null,
                tab: this.$route.query.tab || null
            },

            sortKeys: [
                { text: "정렬순", value: null },
                { text: "조회수", value: "viewCount" },
                { text: "추천수", value: "likeCount" },
                { text: "최신순", value: "createdAt" },
            ],

            searchKeys: [
                { text: "제목 + 내용", value: "subjectcontent" },
                { text: "제목", value: "subject" },
            ],
        };
    },
    async mounted() {
        await this.init();
        
        this.$nextTick(() => {
        this.filter.tab = Number(this.$route.query.tab)
        })
    },
    methods: {
        async init() {
            let { category } = await api.v1.boards.categories.get({ code: this.code });

            this.category = category;
            await this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter },
                });
                return;
            }
            let { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: { ...this.filter },
            });

            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit) || 1;
        },
        input(_category, tab) {
            this.filter._category = _category;
            this.filter.tab = tab;
            this.page = 1;
            this.$router.push({
                query: { ...this.filter },
            });
            this.search();
        },
        move(board) {
            this.$router.push(`/information/issue/${board._id}`);
        },
    },
};
</script>
